export default {
	conversation: {
		header: {
			title:
				'Hey {{firstName}}! Is there anything I should keep in mind for our chat?',
			subtitle:
				'Important note: keep in mind the AldoWise is limited to April 2023 data',
		},
		textAreaPlaceholder: 'Type a message',
		disclaimer:
			'AldoWise Dec 15 Version. Free Research Preview. AldoWise may produce inaccurate information about people, places, or facts',
	},
	deleteConversationModal: {
		header: {
			title: 'Delete conversation?',
		},
		disclaimer: 'This will delete ',
		cancel: 'Cancel',
		deleteChat: 'Delete'
	},
	head: { title: 'AldoWise', description: 'Welcome to the Aldo Group AI App' },
	login: {
		title: 'Welcome to AldoWise',
		imageTitle: 'Your own personal assistant',
		imageSubtitle: 'Help build documents and presentations',
		type: 'Sign in',
		loginButton: '{{type}} with {{label}}',
	},
	sidebar: {
		newChat: 'New Chat',
		newConversation: 'New Conversation',
		clearChats: 'Clear all chats',
		toggleMenu: 'Toggle Menu',
		logout: 'Logout',
	},
}
