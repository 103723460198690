export default {
	conversation: {
		header: {
			title:
				"Salut {{firstName}}! Y a-t-il quelque chose que je devrais garder à l'esprit pour notre conversation?",
			subtitle:
				"Remarque importante: gardez à l'esprit qu'AldoWise est limité aux données d'Avril 2023.",
		},
		textAreaPlaceholder: 'Saisissez un message',
		disclaimer:
			'Version AldoWise du 15 Décembre. Aperçu de la recherche gratuite. AldoWise peut produire des informations inexactes sur des personnes, des lieux ou des faits',
	},
	deleteConversationModal: {
		header: {
			title: 'Effacer la discussion?',
		},
		disclaimer: 'Cela supprimera ',
		cancel: 'Annuler',
		deleteChat: 'Effacer'
	},
	head: {
		title: 'AldoWise',
		description: "Bienvenue sur l'application IA du Groupe Aldo",
	},
	login: {
		title: 'Bienvenue à AldoWise',
		imageTitle: 'Votre propre assistant personnel',
		imageSubtitle: 'Aide à la création de documents et de présentations',
		type: 'Se connecter',
		loginButton: '{{type}} avec {{label}}',
	},
	sidebar: {
		newChat: 'Nouvelle Discussion',
		newConversation: 'Nouvelle Conversation',
		clearChats: 'Effacer toutes les discussions',
		toggleMenu: 'Basculer le menu',
		logout: 'Se déconnecter',
	},
}
